import React from 'react';
import Images from "../../assets/images";

const Footer = () => {
    const { Linkedin, Instagram, XIcon, AppStore, PlayStore } = Images;
    return (
        <div className='footerContainer'>
            <div className='Footer'>
                <div className='leftArea'>
                    <div className='FooterText'>Download App</div>
                    <div className='buttonGroup'>
                        <a href="https://apps.apple.com/in/app/tf-masters/id6474083932" target="_blank" rel="noopener noreferrer">
                            <button><img src={AppStore} alt="appstore" /> App Store</button>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.venzo.tfm&pli=1" target="_blank" rel="noopener noreferrer">
                            <button>
                                <img src={PlayStore} alt="playstore" />
                                Play Store
                            </button>
                        </a>

                    </div>
                    <div className='contactGroup'>
                        <a href="https://www.linkedin.com/company/tfmasters/about/" target="_blank" rel="noopener noreferrer">
                            <img src={Linkedin} alt="Linkedin" />
                        </a>
                        <a href="https://www.instagram.com/tradefinancemasters/" target="_blank" rel="noopener noreferrer">
                            <img src={Instagram} alt="Instagram" />
                        </a>
                        <a href="https://twitter.com/TF_Masters" target="_blank" rel="noopener noreferrer">
                            <img src={XIcon} alt="XIcon" />
                        </a>

                    </div>
                </div>
                <div className='rightArea'>

                    <div className='contactGroup'>
                        <a href="mailto: tfmasters@venzotechnologies.com"><p style={{ borderRight: '1px solid grey' }}>tfmasters@venzotechnologies.com</p></a><a href="tel:98405 94865"><p>+91 98405 94865 </p></a>
                    </div>
                </div>
            </div>
            <div className='copyRightsText'>
                <p>
                    &#169;Copyrights 2024, All rights reserved.
                </p>
                <div className='list'>
                    <p><a href="https://tfmasters.com/#what_make_us_different">Home</a></p>
                    {/* <p><a href="#howItWorks">How its works</a></p>
                    <p><a href="#pricing">Pricing</a> </p> */}
                    <p> <a href="https://tfmasters.com/#comingsoon">Coming Soon</a></p>
                    <p><a href="https://tfmasters.com/#faq">FAQ</a></p>
                    <p><a href="privacy-policy">Privacy Policy</a></p>
                    <p><a href="terms-and-conditions">Terms & Conditions</a></p>
                </div>

            </div>

        </div>
    )
}

export default Footer;