import Images from "../../assets/images";
const MakeUsDifferent = () => {
    const { MakeUsDifferent, Tinyweb } = Images
    return (
        <div className="container" id="what_make_us_different">
            <div className="heading">What Make us Different</div>
            <div className="description">
                Experience our unique blend of cutting-edge curriculum and real-world  insights, tailored specifically for trade finance professionals. Explore  what sets us apart
            </div>

            <div className="makeUsDifferentMainContainer">
                <div className="leftsideContainer">
                    <div className="cardContainer">
                        <div className="Card">
                            <img class="card_pic" src={Tinyweb} width={ "auto" } height={ "auto" } alt="web pattern" />
                            {/* <div className="headingTxt">Tailored to you</div> */}
                            <div className="desc">
                            Content curated by Trade Finance Specialists
                            {/* No matter your experience level,
                                Let’s start your first Trade Finance Course */}
                                </div>
                        </div>
                        <div className="circleContainer">
                            <div className="CircleGreen">
                                <div className="dot"></div>
                            </div>
                            <div className="line"></div>
                        </div>
                    </div>

                    <div className="cardContainer">
                        <div className="Card">
                            <img class="card_pic" src={Tinyweb} width={ "auto" } height={ "auto" } alt="web pattern" />
                            {/* <div className="headingTxt">Students</div> */}
                            <div className="desc">
                            {/* Use TF Masters real world practice to reinforce
                                what you’ve learned & get you ready for big
                                moment */}
                                Information that helps you in day-to-day operations
                                </div>
                        </div>
                        <div className="circleContainer">
                            <div className="CircleRed">
                                <div className="dot"></div>
                            </div>
                            <div className="line"></div>
                        </div>
                    </div>
                </div>
                <div className="centerImageContainer">
                    <img src={MakeUsDifferent} alt="MakeUsDifferent" width={ "260" } height={ "507" } />

                </div>
                <div className="rightsideContainer">


                    <div className="cardContainer">
                        <div className="circleContainer">
                            <div className="line"></div>
                            <div className="CircleYellow">
                                <div className="dot"></div>
                            </div>

                        </div>
                        <div className="Card">
                            <img class="card_pic" src={Tinyweb} width={ "auto" } height={ "auto" } alt="web pattern" />
                            {/* <div className="headingTxt">Bite-Sized</div> */}
                            <div className="desc">
                            {/* Go step-by-step through our unique courses.
                                & gradually advance your skills with practice */}
                                Bite-Sized and easy to digest
                                </div>

                        </div>

                    </div>


                    <div className="cardContainer">
                        <div className="circleContainer">
                            <div className="line"></div>
                            <div className="CircleBlue">
                                <div className="dot"></div>
                            </div>

                        </div>
                        <div className="Card">
                            <img class="card_pic" src={Tinyweb} width={ "auto" } height={ "auto" } alt="web pattern" />
                            {/* <div className="headingTxt">Professionals</div> */}
                            <div className="desc">
                            {/* You can learn something totally new to
                                advance your career. */}
                                Interactive tools & resources helpful to experts and newbies
                                 </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default MakeUsDifferent;