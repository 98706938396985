import React, { useEffect } from 'react';
// import ThreeStepJourney from "./threeStepJourney";
import MakeDifferent from "./makeUsDifferent";
import FutureGoals from "./futureGoals";
import Learning from './learning';
// import Plan from "./plan";
// import Testimonial from './testimonial';
import FAQ from './faq';
import OurApp from './ourApp';
// import GetStarted from './getStarted';
import Images from '../../assets/images';
// import "./landingPage.scss"
const Landingpage = () => {

    const { MobileGroup, AppStore, PlayStore } = Images

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const target = document.querySelector(hash);
            if (target) {
                target.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [window.location.hash])
    return (
        <div className='landingPageMainContainer'>
            <div className='section1' id="home">
                {/* <h1>“World's First Trade Finance Community App<br /> Coming Soon!”</h1> */}
                <h1>Launching the world’s very first Trade<br /> Finance community app!</h1>
                <div className='headText' style={{ marginBottom: "3rem"}}>
                    Your Gateway to Trade Finance Expertise
                </div>
                <div className='buttonGroup'>
                    <a href="https://apps.apple.com/in/app/tf-masters/id6474083932" target="_blank" rel="noopener noreferrer">
                        <button><img src={AppStore} alt="appstore" /> App Store</button>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.venzo.tfm&pli=1" target="_blank" rel="noopener noreferrer">
                        <button>
                            <img src={PlayStore} alt="playstore" />
                            Play Store
                        </button>
                    </a>
                </div>

                <img src={MobileGroup} fetchpriority="high" alt={"mobileGroup"} className='mobileGroup' loading="lazy" />
            </div>
            <MakeDifferent />
            <FutureGoals />
            <Learning />
            {/* <Plan /> */}
            {/* <Testimonial /> */}
            <FAQ />
            <OurApp />
            {/* <GetStarted /> */}
        </div>
    )

}
export default Landingpage