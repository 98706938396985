import React, { Suspense, lazy }from 'react';
import "./layout.scss";
const Header = lazy(() => import('./header.js'));
const Footer = lazy(() => import('./footer.js'));
const LandingPage = lazy(() => import('../LandingPage/landingPage'));
const Layout = () => {

    return (
        <div className="layoutMainContainer">
        <Suspense fallback={<div>TFM</div>}>
            <Header />
            <div>
                <LandingPage/>
            </div>
            <Footer />
        </Suspense>
        </div>
    )

}

export default Layout;