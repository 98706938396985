import TFMasters from "./images/tfmasters.webp";
import AppStore from "./images/appstore.webp";
import PlayStore from "./images/playstore.webp";
import MobileGroup from "./images/MobileGroup1.webp";
import StepsIcon1 from "./images/stepsIcon1.webp";
import StepsIcon2 from "./images/stepsIcon2.webp";
import EnjoyApp from "./images/enjoyApp.webp";
import AndroidAndApple from "./images/androidAndApple.svg";
import LearningWeb from "./images/LearningWeb.webp";
import Team from "./images/team.webp";
import Individuals from "./images/individuals.webp";
import Tick from "./images/tick.svg";
import OurApp from "./images/ourApp(1).webp";
import Instagram from "./images/instagram.webp";
import Linkedin from "./images/linkedin.webp";
import XIcon from "./images/xIcon.webp";
import Send from "./images/send.svg";
import Logo from "./images/logo.svg";
import MakeUsDifferent from "./images/makeUsDifferents.webp";
import Course from "./images/course.webp";
import Trade from "./images/trade.webp";
import Forum from "./images/forum.webp";
import Job from "./images/job.webp";
import ComingSoon from "./images/comingSoon.webp";
import MaskGroup from  "./images/MaskGroup.webp"
import Window_design from "./images/window_design.webp"
import Tinyweb from "./images/tiny_web.webp"
const Images = {
    TFMasters, AppStore, PlayStore, MobileGroup, StepsIcon1, StepsIcon2,  EnjoyApp, AndroidAndApple,
    LearningWeb, Team, Individuals, Tick,OurApp, Instagram, Linkedin,
    XIcon, Send, Logo, MakeUsDifferent, Course, Trade, Forum, Job,
    ComingSoon,MaskGroup,Window_design,Tinyweb
}
export default Images