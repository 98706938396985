import React from 'react'
import Nav from '../Layouts/header'
import Footer from '../Layouts/footer'
import "./landingPage"

export default function PrivacyPolicy() {
  return (
    <div class="" style={{ backgroundColor: "#f5f5f5", fontFamily: "Urbanist"}} >
        <Nav />

        <div class="privacy-policy bg-white shadow-md rounded-lg mx-auto px-4 py-8">
            <h1 class="text-3xl font-bold mb-6 text-gray-800">Terms And Conditions</h1>
            
            <div class="bg-white shadow-md rounded-lg p-6">
                <h2 class="text-xl font-semibold mb-4 text-gray-700">1. Acceptance of Terms</h2>
                <p class="mb-4 text-gray-600">By downloading and using the TFMasters App, you agree to the terms and conditions outlined here.</p>
                
                <h2 class="text-xl font-semibold mb-4 text-gray-700">2. User Conduct</h2>
                <p class="mb-4 text-gray-600">You must use the app for lawful purposes and refrain from engaging in any activity that disrupts other users or violates any applicable laws.</p>

                <h2 class="text-xl font-semibold mb-4 text-gray-700">3. Content Ownership and Plagiarism</h2>
                <p class="mb-4 text-gray-600">All content within the TFMasters App, including text, quizzes, and updates, is the intellectual property of Venzo Technologies Private Limited. Users are prohibited from copying, reproducing, or plagiarizing any content without proper attribution. Any violation of this policy may result in termination of access to the app and legal action, if necessary.</p>
            
                <h2 class="text-xl font-semibold mb-4 text-gray-700">4. Data Privacy</h2>
                <p class="mb-4 text-gray-600">User data collected through the app will be handled according to our privacy policy.</p>

                <h2 class="text-xl font-semibold mb-4 text-gray-700">5. Liability</h2>
                <p class="mb-4 text-gray-600">Venzo Technologies Private Limited is not liable for any inaccuracies or damages resulting from the use of the app.</p>
            
                <h2 class="text-xl font-semibold mb-4 text-gray-700">6. Modifications</h2>
                <p class="mb-4 text-gray-600">Venzo Technologies Private Limited reserves the right to modify these terms at any time.</p>

            </div>
        </div>

        <Footer />
    </div>
  )
}
